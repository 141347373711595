import { defineStore } from "pinia"
import { ref } from "vue"
import { usePage } from "@inertiajs/vue3"
export const useAssetStore = defineStore("asset", () => {
	const assetList = ref([])
	const assetListLoading = ref(false)

	async function populateAssetList(project) {
		if (this.assetListLoading || !project?.slug) return

		this.assetListLoading = true
		// const project = page.props.project
		let filterString = ""
		const curRoute = route("dashboard.project.assets.index", {
			project: project.slug,
			team: usePage().props.currentTeam?.slug,
			// filter: "image",
		})
		const response = await http.get(curRoute)
		assetList.value = response.data.map((item) => ({
			id: item.id,
			text: item["name"],
			value: item["id"],
			asset_url: item["asset_url"],
		}))
		this.assetListLoading = false
	}

	function setAssetList(value) {
		this.assetList = value
	}

	function addAsset(value) {
		this.assetList.push({
			id: value.id,
			text: value.name,
			value: value.id,
			asset_url: value.asset_url,
		})
	}

	function updateAssetPreview(dataModel) {
		if (!dataModel?.asset_list) return {}
		return Object.entries(dataModel.asset_list).reduce(
			(assetPreview, [key, value]) => {
				if (!value) return assetPreview
				if (typeof value === "object") {
					assetPreview[key] = Object.entries(value).reduce(
						(repeatableFieldValue, [subKey, subValue]) => {
							repeatableFieldValue[subKey] = Object.entries(subValue).reduce(
								(subField, [subFieldKey, subFieldValue]) => {
									subField[subFieldKey] = assetList.value.find(
										(asset) => asset.id === subFieldValue,
									).asset_url
									return subField
								},
								{},
							)
							return repeatableFieldValue
						},
						{},
					)
				} else {
					assetPreview[key] = assetList.value.find(
						(asset) => asset.id === value,
					).asset_url
				}
				return assetPreview
			},
			{},
		)
	}

	return {
		assetList,
		assetListLoading,
		setAssetList,
		addAsset,
		populateAssetList,
		updateAssetPreview,
	}
})
