// Utilities
import { defineStore } from "pinia"

const RECENT_PROJECT_LIST_KEY = "recent-project-list"
const MAX_RECENT_PROJECTS = 4

export const useProjectStore = defineStore("projects", {
	state: () => ({
		projectList: [],
		breadCrumbs: [],
		recentProjects:
			JSON.parse(localStorage.getItem(RECENT_PROJECT_LIST_KEY)) || {},
		projectsPaginated: {},
		activeProject: null,
	}),

	getters: {
		getPaginatedProjectList() {
			if (!this.projectsPaginated || !this.projectsPaginated.data) return null
			return this.projectsPaginated.data
		},
		getActiveProjectId() {
			if (!this.activeProject) return null
			return this.activeProject.id
		},
		getActiveProject() {
			return this.activeProject
		},
	},

	actions: {
		setProjectList(value) {
			this.projectList = value
		},

		setProjectsPaginated(value) {
			this.projectsPaginated = value
		},

		setActiveProject(adminId, teamSlug, value) {
			this.activeProject = value

			if (!value || !adminId) return

			value.team_slug = teamSlug
			try {
				// Initialize admin's recent projects array if needed
				if (!this.recentProjects[adminId]) {
					this.recentProjects[adminId] = []
				}

				// Remove project if it already exists
				this.recentProjects[adminId] = this.recentProjects[adminId].filter(
					(p) => p.id !== value.id,
				)

				// Add new project at the beginning
				this.recentProjects[adminId].unshift(value)

				// Limit to max recent projects
				if (this.recentProjects[adminId].length > MAX_RECENT_PROJECTS) {
					this.recentProjects[adminId] = this.recentProjects[adminId].slice(
						0,
						MAX_RECENT_PROJECTS,
					)
				}

				localStorage.setItem(
					RECENT_PROJECT_LIST_KEY,
					JSON.stringify(this.recentProjects),
				)
			} catch (err) {
				console.error("Error updating recent projects:", err)
				localStorage.setItem(RECENT_PROJECT_LIST_KEY, JSON.stringify({}))
			}
		},

		setBreadCrumbs(value) {
			this.breadCrumbs = value
		},

		getRecentProjects(adminId) {
			if (!this.recentProjects[adminId]) return null
			return this.recentProjects[adminId].filter(
				(item) => !Array.isArray(item) && item?.id,
			)
		},

		removeFromRecents(adminId, projectId) {
			try {
				if (!this.recentProjects[adminId]) {
					this.recentProjects[adminId] = []
				} else {
					this.recentProjects[adminId] = this.recentProjects[adminId].filter(
						(project) => project.id !== projectId,
					)
					localStorage.setItem(
						RECENT_PROJECT_LIST_KEY,
						JSON.stringify(this.recentProjects),
					)
				}
			} catch (err) {
				console.error("Error removing from recents:", err)
				localStorage.setItem(RECENT_PROJECT_LIST_KEY, JSON.stringify({}))
			}
		},
	},
})
