<template>
	<Teleport to="body">
		<MDBModal v-model="localIsOpen" @update:model-value="updateIsOpen">
			<MDBModalHeader>
				<MDBModalTitle>
					<h5>AI Builder</h5>
				</MDBModalTitle>
			</MDBModalHeader>
			<MDBModalBody class="p-0 d-flex flex-column" style="height: 60vh">
				<AiChat
					ref="chatContainer"
					:messages="chatHistory"
					:loading="isLoading"
					@send-message="handleSendMessage"
					@save-message="handleSave"
					@clear-chat="handleClearChat"
					@keydown.enter.prevent
				/>
			</MDBModalBody>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import { ref, watch, nextTick, computed, reactive, onMounted } from "vue"
import {
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalTitle,
} from "mdb-vue-ui-kit"
import AiChat from "@/Components/Chat/AiChat.vue"
import axios from "@/Services/http"
import { usePage } from "@inertiajs/vue3"

const props = defineProps({
	isOpen: {
		type: Boolean,
		required: true,
	},
	systemPrompt: {
		type: String,
		default: null,
	},
	field: {
		type: Object,
		required: true,
	},
	currentValue: {
		type: [Object, String, Array, Number, Boolean, null],
		required: false,
		default: null,
	},

	tabsList: {
		type: Array,
		required: false,
		default: () => [],
	},
	outputFormat: {
		type: Object,
		required: false,
		default: () => {},
	},
})

const emit = defineEmits(["update:isOpen", "save"])

// Local state
const localIsOpen = ref(props.isOpen)
const chatHistory = ref([])
const isLoading = ref(false)
const pendingMessageId = ref(null)

const chatContainer = ref(null)
// Computed property for field change information
const fieldChangeInfo = computed(() => {
	if (props.field.aiBuilder.fullForm) {
		return ""
	}
	if (!props.currentValue) {
		return ""
	}

	let fieldChangeString = ""

	if (props.field.label) {
		fieldChangeString += `The field you are helping build is labeled ${props.field.label} and currently set to `
	} else {
		fieldChangeString += `The field you are helping build is currently set to `
	}

	fieldChangeString += "```json\n"

	fieldChangeString +=
		typeof props.currentValue === "string"
			? props.currentValue
			: JSON.stringify(props.currentValue)

	fieldChangeString += "\n```"

	fieldChangeString += `\n\nIn your response, be aware that you are assisting the user in editing existing text for this field, but you do not need to include the existing text in your response.`
	return fieldChangeString
})

const outputFormatComputed = computed(() => {
	return {
		chat: "Friendly intro text to the user",
		chat_end: "Friendly outro text to the user",
		options: [props.outputFormat],
	}
})

// Sync modal state with parent
watch(
	() => props.isOpen,
	(newValue) => {
		localIsOpen.value = newValue
		if (newValue) {
			// Scroll to bottom when modal opens
			nextTick(() => {
				chatContainer.value.scrollToBottom()
			})
		}
	},
)

const updateIsOpen = (value) => {
	emit("update:isOpen", value)
}

const roleDetails = computed(() => {
	return (
		props.field.aiBuilder.role ||
		"Your role is to interact with the user to understand their requirements and assist them in writing text for a form field."
	)
})

const toneDetails = computed(() => {
	return (
		props.field.aiBuilder.tone ||
		"Maintain a professional yet approachable tone while engaging with users to understand their requirements."
	)
})

const finalSystemPrompt = computed(() => {
	const baseFormat = JSON.stringify(outputFormatComputed.value, null, 4)
	const multipleFormat = JSON.stringify(
		{
			chat: "Friendly intro text to the user",
			chat_end: "Friendly outro text to the user",
			options: [props.outputFormat, props.outputFormat],
		},
		null,
		4,
	)

	let prompt = `${roleDetails.value}

${toneDetails.value}

When it's time to provide an output example, your responses should ALWAYS follow this structure using valid JSON:
\`\`\`json
${baseFormat}
\`\`\`

Example with multiple options:
\`\`\`json
${multipleFormat}
\`\`\`

Ensure that newlines and control characters are properly escaped in your responses so we can decode them. You are free to use markdown in your responses as long as it is properly escaped.
`

	// Add field-specific format details if provided
	if (props.field.aiBuilder.outputFormatDetails) {
		prompt += `\n\nAdditional format details: ${props.field.aiBuilder.outputFormatDetails}`
	}

	// Add custom examples if provided
	if (props.field.aiBuilder.examples?.length) {
		prompt += "\n\n*Additional examples:"
		props.field.aiBuilder.examples.forEach((example) => {
			prompt += `\n${typeof example === "string" ? example : JSON.stringify(example)}`
		})
	}

	// Add field change context if relevant
	if (fieldChangeInfo.value) {
		prompt += `\n${fieldChangeInfo.value}`
	}

	return prompt
})

const adminAiSessionId = ref(null)

const parseAIResponse = (responseText) => {
	try {
		// Look for content between ```json and ``` markers
		const jsonMatch = responseText.match(/```json\s*([\s\S]*?)\s*```/)
		let jsonString = jsonMatch ? jsonMatch[1].trim() : responseText

		try {
			let result = JSON.parse(jsonString)

			// If result is an array, wrap it in the expected format
			if (Array.isArray(result)) {
				result = {
					options: result,
				}
			}

			return result
		} catch (error) {
			// Common cleanup patterns
			jsonString =
				jsonString
					// Remove trailing commas in arrays/objects
					.replace(/,(\s*[}\]])/g, "$1")
					// Fix missing quotes around property names
					.replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3')
					// Handle single quotes
					.replace(/'/g, '"')
					// Look for array-like structure if no json block found
					.match(/\[\s*{[\s\S]*?}\s*\]/)?.[0] || jsonString

			try {
				const result = JSON.parse(jsonString)
				return result
			} catch (error) {
				return {
					chat: jsonString,
				}
			}
		}
	} catch (error) {
		console.error("Error parsing AI response:", error)
		console.error("Raw response:", responseText)
		return [
			{
				bot: "I apologize, but I had trouble formatting my response. Could you try again?",
			},
		]
	}
}

const handleSendMessage = async (message) => {
	// Prevent default form submission behavior
	event.preventDefault()
	// Add user message to chat
	chatHistory.value.push({ role: "user", text: { chat: message } })

	// Add loading message
	pendingMessageId.value = Date.now()
	chatHistory.value.push({
		role: "model",
		loading: true,
		id: pendingMessageId.value,
	})

	try {
		const response = await axios.post(
			route("dashboard.admin-ai-session-messages", {
				team: usePage().props.currentTeam.slug,
			}),
			{
				form_field_type: props.field.type,
				userMessage: message,
				systemPrompt: finalSystemPrompt.value,
				admin_ai_session_id: adminAiSessionId.value,
				project: usePage().props.project ? usePage().props.project.slug : null,
			},
		)

		adminAiSessionId.value = response.data.admin_ai_session_id

		// Replace loading message with actual response
		const messageIndex = chatHistory.value.findIndex(
			(msg) => msg.id === pendingMessageId.value,
		)
		if (messageIndex !== -1) {
			chatHistory.value[messageIndex] = {
				role: "model",
				text: parseAIResponse(response.data.prompt),
			}
		}
	} catch (error) {
		console.log("error", error)
		// Replace loading message with error
		const messageIndex = chatHistory.value.findIndex(
			(msg) => msg.id === pendingMessageId.value,
		)
		if (messageIndex !== -1) {
			chatHistory.value[messageIndex] = {
				role: "model",
				text: {
					chat:
						error.response?.data?.error ||
						"Error: Unable to process your request. Please try again.",
				},
			}
		}
	} finally {
		isLoading.value = false
		pendingMessageId.value = null
	}
}

const handleSave = (messageText) => {
	emit("save", messageText)
	localIsOpen.value = false
}

const handleClearChat = () => {
	chatHistory.value = []
	adminAiSessionId.value = null
}

onMounted(() => {})

const fieldLabels = reactive({})
</script>
