import markdownit from "markdown-it"
import DOMPurify from "dompurify"
export const isJsonMessage = (message) => {
	let jsonMarkerMatch =
		message.includes("```json") ||
		message.startsWith("[") ||
		message.startsWith("{")

	if (jsonMarkerMatch) {
		return true
	}

	return false
}

export const parseJsonMessage = (message) => {
	message = message.trim()
	let jsonMatch = message.match(/```json\n([\s\S]*?)```/)
	if (!jsonMatch) {
		if (message.startsWith("[") || message.startsWith("{")) {
			try {
				return JSON.parse(message)
			} catch (e) {
				console.error("Failed to parse JSON:", e)
				return null
			}
		}
		return null
	}

	try {
		const parsed = JSON.parse(jsonMatch[1])
		return Array.isArray(parsed) ? parsed : [parsed]
	} catch (e) {
		console.error("Failed to parse JSON:", e)
		return null
	}
}

export const getTextBeforeJson = (message) => {
	if (message.trim().startsWith("{") || message.trim().startsWith("[")) {
		return ""
	}
	const parts = message.split("```json")
	if (parts.length === 1) {
		return message.trim()
	}
	return parts[0].trim()
}

export const getTextAfterJson = (message) => {
	const parts = message.split("```")
	if (parts.length < 3) {
		return ""
	}
	return parts[parts.length - 1].trim()
}

export const formatMarkdown = (text) => {
	return DOMPurify.sanitize(markdownit().render(text))
}

export const formatMessage = (message) => {
	return formatMarkdown(message)
}

export const sanitizeMessage = (message) => {
	return DOMPurify.sanitize(message)
}
