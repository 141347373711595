import * as Sentry from "@sentry/vue"

let initVars = {
	sentry_enabled: false,
}
try {
	initVars = JSON.parse(window.atob(window.efboot))
} catch (e) {
	console.error(e)
}

export default async function loadSentry(app) {
	if (initVars["sentry_enabled"]) {
		Sentry.init({
			app,
			dsn: initVars["sentry_dsn"],
			release: initVars["app_version"],
			environment: initVars["sentry_environment"],
		})
	}
}
