<template>
	<div class="ai-chat d-flex flex-column h-100">
		<!-- Chat Messages -->
		<div
			ref="chatContainer"
			class="chat-messages p-3 flex-grow-1 overflow-auto"
		>
			<div class="d-flex justify-content-between align-items-center mb-2">
				<h5 class="mb-0">Chat</h5>
				<button
					class="btn btn-danger btn-sm"
					:disabled="messages.length === 0"
					@click="clearChat"
				>
					Clear Chat
				</button>
			</div>
			<div
				v-for="(message, index) in messages"
				:key="index"
				class="message-wrapper d-flex mb-2"
				:class="{ 'justify-content-end': message.role === 'user' }"
			>
				<div
					class="message p-2 px-3 rounded-3"
					:class="[
						message.role === 'user'
							? 'bg-primary text-white'
							: 'bg-secondary text-white',
						message.loading ? 'loading-message' : '',
						message.role === 'model' ? 'pb-4' : '',
					]"
					style="max-width: 75%; position: relative"
				>
					<div v-if="message.loading" class="d-flex align-items-center">
						<div class="spinner-border spinner-border-sm me-2" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
						Thinking...
					</div>
					<template v-else>
						<div v-if="message.text">
							<div
								v-if="message.text.chat"
								class="mb-2"
								v-html="formatMarkdown(message.text.chat)"
							></div>

							<div class="json-message-list">
								<div
									v-for="(item, idx) in message.text.options"
									:key="idx"
									class="mb-2"
								>
									<template v-if="item.label">
										<strong>{{ item.label }}</strong>
										<br />
									</template>
									{{ sanitizeMessage(item.value) }}
									<button
										class="btn btn-link btn-sm save-button"
										@click="saveMessage(JSON.stringify(item))"
									>
										Apply
									</button>
								</div>
							</div>

							<div
								v-if="message.text.chat_end"
								class="mt-2"
								v-html="formatMarkdown(message.text.chat_end)"
							></div>
						</div>
						<div v-else v-html="formatMessage(message.text.chat)"></div>
					</template>
				</div>
			</div>
		</div>

		<!-- Chat Input -->
		<div class="chat-input p-3 border-top">
			<div class="input-group">
				<input
					v-model="newMessage"
					type="text"
					class="form-control"
					placeholder="Describe what you want..."
					@keyup.enter="sendMessage"
				/>
				<button class="btn btn-primary" @click="sendMessage">
					<FontAwesomeIcon :icon="lookupIcon('paper-plane', 'fas')" />
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, nextTick, defineExpose } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"

import {
	isJsonMessage,
	parseJsonMessage,
	getTextBeforeJson,
	getTextAfterJson,
	formatMarkdown,
	formatMessage,
	sanitizeMessage,
} from "@/Utils/aiChatMessageHelpers.js"

const props = defineProps({
	messages: {
		type: Array,
		default: () => [],
	},
	loading: {
		type: Boolean,
		default: false,
	},
})

const emit = defineEmits(["send-message", "save-message", "clear-chat"])

const newMessage = ref("")
const chatContainer = ref(null)

// Scroll to bottom when new messages arrive
watch(
	() => props.messages.length,
	async () => {
		await nextTick()
		scrollToBottom()
	},
)

const scrollToBottom = () => {
	if (chatContainer.value) {
		chatContainer.value.scrollTop = chatContainer.value.scrollHeight
	}
}

const sendMessage = () => {
	if (!newMessage.value.trim()) return

	emit("send-message", newMessage.value)
	newMessage.value = ""
}

const saveMessage = (messageText) => {
	emit("save-message", messageText)
}

const clearChat = () => {
	emit("clear-chat")
}
defineExpose({
	scrollToBottom,
})
</script>

<style scoped>
.ai-chat {
	min-height: 0;
}

.chat-messages {
	height: 0;
	min-height: 0;
}

.chat-messages::-webkit-scrollbar {
	width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.chat-input {
	flex-shrink: 0;
}

.loading-message {
	opacity: 0.7;
}

.save-button {
	padding: 0;
	font-size: 0.75rem;
}

.json-message-list {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.json-message-list .save-button {
	margin-top: 0.25rem;
}
</style>
